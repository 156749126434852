.form-container {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .form {
        width: 40%;
        position: relative;
        border: 1px solid gray;
    }

    .logo-container {
        background-color: #A12219 !important;
        background-color: #fff;
        text-align: center;
        padding: 1rem;
        font-weight: bolder;
        font-size: larger;
       
    }

    .input-container {
        width: 100%;

        .group-wrapper {
            padding: 1rem;
        }
        .group {
            
            display: flex;
            line-height: 30px;
            align-items: center;
            position: relative;
            margin: 1rem 0;
          }
          .submit {
            background-color: #A12219 !important;
            font-weight: bolder;
            font-size: larger;
          }
          
          .input {
            width: 100%;
            height: 45px;
            line-height: 30px;
            padding: 0 1rem;
            border: 2px solid transparent;
            border-radius: 10px;
            outline: none;
            background-color: #f8fafc;
            color: #0d0c22;
            transition: .5s ease;
          }
          
          .input::placeholder {
            color: #94a3b8;
          }
          
          .input:focus, input:hover {
            outline: none;
            border-color: rgba(129, 140, 248);
            background-color: #fff;
            box-shadow: 0 0 0 5px rgb(129 140 248 / 30%);
          }
          
    }
}

@media (max-width: 768px) {
  .form-container {
    width: unset !important;
    margin: 2rem;
  }
  .form {
    width: 100% !important;
  }
 }

 @media (max-width: 1023px) {
    .form {
      width: 80% !important;
    }
   }