@import "./styles/login.scss";
@import "./styles/swal.scss";

body {
  padding-bottom: 2rem;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #222222
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.header {
  background-color: blue;
}
.view {
  display: none !important;
}

.admin-wrapper {
  width: 60% !important;
}

@media (max-width: 936px) {
  .admin-wrapper {
    width: 80% !important;
  }
}

@media (max-width: 768px) {
  .admin-wrapper {
    width: 100% !important;
  }
  
  .header-cell, .action-cell {
    min-width: 200px !important;
  }
  .view {
    display: inline !important;
  }

  .modal-wrapper {
    width: 80% !important;
  }

  .form-wrapper {
    flex-direction: column !important;
    
  }

  .form-wrapper button {
    margin-top: 1rem !important;
    margin-left: 0 !important;
  }
  /* .MuiDataGrid-main {
    width: 400px;
    padding-left: 1rem;
    padding-right: 1rem;
  } */

  
 }

 @media screen and (max-width: 578px) {
    .MuiDataGrid-main {
    width: 550px;
  }
} 

